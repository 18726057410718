import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';


/** Inject With Credentials into the request */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {


      let credentials = localStorage.getItem('credentials');

      if ( credentials !== null) {
        const token = JSON.parse(credentials).token;

      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`
        }
      });

      
    }

      


    

    return next.handle(request);
  }
}
