import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';

// MATERIAL
import { MaterialModule } from '@app/modules/material.module';

// ANIMATIONS
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// CUSTOM PROVIDERS
import { HighchartsChartModule } from 'highcharts-angular';
import { AuthInterceptor } from '@app/services/http/auth.interceptor';

// ENVIRONMENT
import { environment } from '@env/environment';

//LANGUAGE
import { MAT_DATE_LOCALE } from '@angular/material/core';
import localeEs from '@angular/common/locales/es';
import localeEn from '@angular/common/locales/en';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { LiteralesService } from '@app/services/literalesWs/literales.service';
import { I18nService } from '@app/services/i18n/i18n.service';

// CARRUSEL
import { SlickCarouselModule } from 'ngx-slick-carousel';

// PIPES
import { AppPipesModule } from './pipes/pipes.module';

registerLocaleData(localeEs, 'es');

@NgModule({
  exports: [TranslateModule],
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppPipesModule,
    HttpClientModule,
    HighchartsChartModule,
    AppRoutingModule,
    SlickCarouselModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useClass: LiteralesService,
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    I18nService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: LOCALE_ID,
      useFactory: ( _translateService: TranslateService) => {
          let lang = localStorage.getItem('language');

          if ( !lang ) {
              lang = _translateService.currentLang;
          }

          moment.locale( lang );

          if ( lang === 'es' ) {
              registerLocaleData(localeEs, 'es');
              return 'es-ES';
          } else {
              registerLocaleData(localeEn, 'en');
              return 'en-EN';
          }

            // return _translateService.currentLang;
      },
      deps: [TranslateService]
  },
  {
      provide: MAT_DATE_LOCALE,
      useFactory: ( _translateService: TranslateService) => {
          let lang = localStorage.getItem('language');

          if ( !lang ) {
              lang = _translateService.currentLang;
          }

          moment.locale( lang );

          if ( lang === 'es' ) {
              registerLocaleData(localeEs, 'es');
              return 'es-ES';
          } else {
              registerLocaleData(localeEn, 'en');
              return 'en-EN';
          }
      },

      deps: [TranslateService]
  },
  {
    provide: LOCALE_ID,
    useValue: 'es'
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
