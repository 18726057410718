import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtml } from './safeHtml.pipe';
import { ReplaceSimpleQuotes } from './comillas.pipe';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SafeHtml,
    ReplaceSimpleQuotes
  ],
  exports: [
    SafeHtml,
    ReplaceSimpleQuotes
  ]
})
export class AppPipesModule { }
