import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({name: 'replaceSimpleQuotes'})
export class ReplaceSimpleQuotes implements PipeTransform {
  transform(value: string): string {
    return value.replace(/\''/g, `'`);
  }
}
