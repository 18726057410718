import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import 'firebase/analytics';
import * as firebase from 'firebase/app';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { FirebaseService } from '@app/services/firebase/firebase.service';
import { UtilsService } from '@app/services/utils/utils.service';
import { SessionService } from '@app/services/session/session.service';
import { TranslateService } from '@ngx-translate/core';
import { I18nService} from '@app/services/i18n/i18n.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { merge, Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Circuito Nacional Holocrón';

  constructor(
    private router: Router,
    private firebaseService: FirebaseService,
    private utilsService: UtilsService,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    Window['utils'] = this.utilsService;
  }
 
  ngOnInit() {
    sessionStorage.setItem('reloadMatches', 'true');
    firebase.default.initializeApp(environment.firebaseConfig);
    const analytics = firebase.default.analytics();
    this.firebaseService.requestPermission();
    // firebase.analytics();
    
    this.setTranslates();
    this.router.events.subscribe(e => {
        if (e instanceof NavigationEnd) {
          analytics.logEvent('pantalla_vista', { page: e.urlAfterRedirects} );

         
          if( this.sessionService.getCredentials() !== null ) {
            this.sessionService.updateSession(JSON.parse(this.sessionService.getCredentials())).subscribe((response: any) => {
              if ( response ) {
                const credentials = JSON.parse(this.sessionService.getCredentials());
                this.sessionService.credentials = response;
                if (response.token !== credentials.token ) {
                  this.router.navigate(['/home/user/login']);
                }
              }
            }, (error) => {
            }, () => {
              // all
            });
          }
        }

    });

  }

  private setTranslates() {
    this.translateService.addLangs(['es', 'en']);
    this.translateService.setDefaultLang('es');

    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang.match(/es|en/) ? browserLang : 'es');

    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    merge(this.translateService.onLangChange, onNavigationEnd)
        .pipe(
            map(() => {
                let route = this.activatedRoute;
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => route.data)
        )
        .subscribe(event => {
            const title = event['title'];
            if (title) {
                this.titleService.setTitle(this.translateService.instant(title));
            }
        });
}


}
