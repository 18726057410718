export const environment = {
  production: true,
  hostServerUrl: 'https://circuitoholocron.es/assets/data',
  // hostServerUrl: 'https://circuitoholocron.es/assets/dataTest',
  defaultLanguage: 'es-ES',
  supportedLanguages: ['es-ES'],
  // hostServerUrl: 'https://195.179.193.65:8090/preview/circuitoHolocron.es/assets/data',
  firebaseConfig: {
    apiKey: "AIzaSyAMVrditO7_EPXrhjoXR66slDY_odgI30I",
    authDomain: "holocron-fc984.firebaseapp.com",
    projectId: "holocron-fc984",
    storageBucket: "holocron-fc984.appspot.com",
    messagingSenderId: "413546664619",
    appId: "1:413546664619:web:d4083c47466eb9092c9dee",
    measurementId: "G-L139LNNP3B"
  }
};

