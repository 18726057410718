
import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { environment } from '@env/environment.prod';
import { Location } from '@angular/common'
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})

export class UtilsService {
  public chartSeriesColors = ['#e02323', '#4a4a4a', '#9bdaff', '#8a00f3','#FFC300'];

    constructor(
      private location: Location,
      private ngZone: NgZone,
      private router: Router
    ) {
      window['OsAppPlatform'] = (): { os: string, type: string, device: string } => {
        let _os: string = navigator.userAgent;
        let _type: string = '';
        let _device: string = '';
        if (/i-de/.test(navigator.userAgent)) {
          _type = 'APP';
          _device = navigator.platform;
          if (/Google/.test(navigator.vendor)) {
            _os = 'Android';
          } else if (/Apple/.test(navigator.vendor)) {
            _os = 'iOS';
          }
        } else {
          _type = 'Navigator';
          _os = this.platform;
        }
        return { os: _os, type: _type, device: _device };
      };
    }

    public get isMobile(): boolean | any {
      return window.innerWidth < 640;
    }

    back(): void {
      this.location.back();
    }

    public getFactionShortNameId(id: number ) {
      switch (id) {
        case 1:
          return 'rebeldes';
        case 2:
          return 'imperio';
        case 3:
          return 'clones';
        case 4:
          return 'droides';
        case 5:
          return 'colectivo';
      }
    }

    public getFactionNameNameId(id: number ) {
      switch (id) {
        case 1:
          return 'Rebeldes';
        case 2:
          return 'Imperiales';
        case 3:
          return 'República Galactica';
        case 4:
          return 'Alianza Separatista';
        case 5:
          return 'Colectivo Sombra';
      }
    }

    public get platform(): string {
      const userAgent = navigator.userAgent || navigator.vendor;
      const platform = navigator.platform;
      if (/android/i.test(userAgent)) {
        return 'Android';
      }
  
      if (/iPad|iPad Simulator|iPhone|iPhone Simulator|iPod|iPod Simulator/.test(userAgent)) {
        return 'iOS';
      }
  
      if (/i-DE/.test(userAgent)) {
        if (/iPad|iPad Simulator|iPhone|iPhone Simulator|iPod|iPod Simulator/.test(platform)) {
          return 'iOS';
        } else {
          return 'Android';
        }
      }
  
      return 'desktop';
    }

    public get isIOS(): boolean {
      return this.OsAppPlatform.os === 'iOS';
    }

    public get isAndroid(): boolean {
      return this.OsAppPlatform.os === 'Android';
    }

    public externalUrl(url: string, target?: string) {
      if ( !target ) {
        target = '';
      }

      window.open(url, target);
    }

    public internalUrl(url: String) {
      this.ngZone.run(() => this.router.navigate([url]) );
    }

    public donate() {
      const url = 'https://www.paypal.com/donate?hosted_button_id=ET858JA3NBJMQ';
      this.externalUrl(url, '_system');
    }

    public capitalizeFirstLetter(date: string) {
      return date.charAt(0).toUpperCase() + date.slice(1);
    }

    public logout() {
      localStorage.removeItem('credentials');
      this.router.navigate(['/home/user/login']);
    }

    public get OsAppPlatform(): { os: string, type: string, device: string } {
      let _os: string = navigator.userAgent;
      let _type: string = '';
      let _device: string = '';
      if (/i-de/.test(navigator.userAgent.toLowerCase())) {
        _type = 'APP';
        _device = navigator.platform;
        if (/google/.test(navigator.vendor.toLowerCase())) {
          _os = 'Android';
        } else if (/apple/.test(navigator.vendor.toLowerCase())) {
          _os = 'iOS';
        }
      } else {
        _type = 'Navigator';
        _os = this.platform;
      }
      return { os: _os, type: _type, device: _device };
    }

    public fieldSorter(fields) {
      return function (a, b) {
          return fields
              .map(function (o) {
                  var dir = 1;
                  if (o[0] === '-') {
                     dir = -1;
                     o=o.substring(1);
                  }
                  if (a[o] > b[o]) return dir;
                  if (a[o] < b[o]) return -(dir);
                  return 0;
              })
              .reduce(function firstNonZeroValue (p,n) {
                  return p ? p : n;
              }, 0);
      };
    }

    public randomString(length, chars) {
      let result = '';
      for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
      return result;
  }
}
