
import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { environment } from '@env/environment.prod';
import { Md5 } from 'ts-md5';

@Injectable({
	providedIn: 'root'
})

export class SessionService {
  private urlExistsUser =  `${environment.hostServerUrl}/index.php?op=ExistsUser`;
  private urlNewUser =  `${environment.hostServerUrl}/index.php?op=NewUser`;
  private urlLogin =  `${environment.hostServerUrl}/index.php?op=LoginUser`;
  private urlUpdateToken =  `${environment.hostServerUrl}/index.php?op=UpdateSession`;

  public credentials;

  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

    constructor(
        private httpClient: HttpClient
         ) { }

        public newUser(user: any): Observable<any> {
          return this.httpClient.post<any>(this.urlNewUser, user);
        }

        public existsUser(user: any): Observable<any> {
          return this.httpClient.post<any>(this.urlExistsUser, user);
        }

        public updateSession(credentials: any): Observable<any> {
          return this.httpClient.post<any>(this.urlUpdateToken, credentials);
        }

        public loginUser(user: any): Observable<Object> {
          const minutesToAdd = 30;
          const currentDate = new Date();
          const expirationDate = (new Date(currentDate.getTime() + minutesToAdd*60000)).getTime();

          user._token = Md5.hashStr(`${user.email}1C23H456N78`);
          user._expirationDate = expirationDate;

          return Observable.create((observer: Subscriber<any>) => {
            const subs = this.httpClient.post<any>(this.urlLogin, user)
            .subscribe((data: any) => {
              if ( data.length > 0 ) {
                observer.next(true);
                this.setToken(data[0]);
                this.getLoggedInName.emit(true);
              } else {
                observer.next(false);
              }
              
            }, error => {
              observer.error(error);
            }, () => {
              observer.complete();
            });

          });
        }

        setToken(user: any) {
          const data = {
            username: user.email,
            token: user.token,
            expirationDate: user.expirationDate,
            user_type: Md5.hashStr(user.user_type)
          };

          this.credentials = data;
          localStorage.setItem('credentials', JSON.stringify(data));

        }

        getCredentials() {
          return localStorage.getItem('credentials');
        }
         
        deleteCredentials() {
          localStorage.removeItem('credentials');
        }
         
        isLoggedIn() {
          
          const userCredentials = JSON.parse(this.getCredentials());

          if (userCredentials != null) {
            const now = new Date();
            const actualDate = userCredentials.expirationDate;
            const lastUpdateAux = now.toLocaleString("es-es", {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: "2-digit"}).split(" ");
            const time = lastUpdateAux[1];

            const mdy = lastUpdateAux[0].split('/');
            const month = mdy[0];
            const day = mdy[1];
            const year = mdy[2].substr(0,4);
            const lastUpdate = year + '-' + month + '-' + day + ' ' + time;

            let msDifference =  actualDate - now.getTime();
            let minutes = Math.floor(msDifference/1000/60);

            if ( minutes <= 0 ) {
              this.deleteCredentials();
              return false;
            } else {
              const minutesToAdd = 30;
              const currentDate = new Date();
              const expirationDate = (new Date(currentDate.getTime() + minutesToAdd*60000)).getTime();
              userCredentials.expirationDate = expirationDate;
              userCredentials.lastUpdate = lastUpdate;
              localStorage.setItem('credentials', JSON.stringify(userCredentials));
              if ( this.credentials ) {
                userCredentials.user_type = this.credentials.user_type;
              }
              
              this.credentials = userCredentials;

              return true;
            }
          }
          return false;
        }

        getMD5Holocron(md5: string) {
          // b61a2b240bdc4bcaff3d76d757a7b30d - player
          // c3284d0f94606de1fd2af172aba15bf3 - admin
          // e8dcb94ff4554e24d7ac1a21a3971c5d - 1 Geonosis
          // 063dc4234d6d56f79d3c6002a98cf695 - 2 Alderaan
          // d96b578654a815becd3cfad0a6338d8e - 3 Tatooine
          // 5cb61dccf4ee57f87672aebd9da7f889 - 4 Kamino
          // f5d5b7069c10adb3230ab5d499c0feb2 - 5 Kashyyyk
          // 893e3980a2c6392d3e21b7ee12a6936d - 7 Jakku
          // cb6a1072c7c115995ef28c181a05d2a1 - 8 Endor
          // 5b5606374e4451e608d672caaa34553d - 9 Mustafar
          // 5081cf5ff82a58068064eb21d977c21b - 11 Bespin
          // dfc794f70c3fe466dc8c595fd6d0a46d - 12 Hoth
          // 7f24e1ec4002863ddea24127c256aad4 - 14 Scarif

          switch (md5) {
            case '912af0dff974604f1321254ca8ff38b6':
              return 'player';
            case '712fa2eabd1820df5a615ff82233ab0f':
              return 'adminQW2o2vPMJr';
            case '709f6c669b55b0b03a27a8aac973596f':
              return 'adminQW2o2vPMJr_1';
            case 'd9c3fe22b2d4649b9ed9c28b38f7c3a2':
              return 'adminQW2o2vPMJr_2';
            case 'aec8456490fe41b06850a7ef76d48dd5':
              return 'adminQW2o2vPMJr_3';
            case '8425264fd053039da57650241eb35741':
              return 'adminQW2o2vPMJr_4';
            case '67d9eb0fea1d9df8f7d290a9cc96a86a':
              return 'adminQW2o2vPMJr_5';
            case '48ceb1b2815ba02c169c7e6d8aa7f4f7':
              return 'adminQW2o2vPMJr_7';
            case '6865d3bb0527ab6d66f5fa456867ff58':
              return 'adminQW2o2vPMJr_8';
            case '29638df231ba2ee62abf1e9605cbdc4b':
              return 'adminQW2o2vPMJr_9';
            case '33290a81dd7c009f7e3c2b6ca2e5229f':
              return 'adminQW2o2vPMJr_11';
            case '43a6829f5a686a685f116b955505381c':
              return 'adminQW2o2vPMJr_12';
            case '9b80af88f77d942a15036aa48f73e200':
              return 'adminQW2o2vPMJr_14';
            case '6270bd3631552cab84f9cd0563eab752':
              return 'adminTournamentGestorQW2o2vPMJr';
          }

        }
        
}

