import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/messaging';
import { FirebaseApp } from '@angular/fire';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
    private GAevent: {
      type: string,
      value: string
    };

    constructor() { }

    public setGALogEvent( GAevent: { type: string, value: string } ) {
      const analytics = firebase.default.analytics();

      if ( GAevent ) {
        analytics.logEvent( GAevent.type, { name: GAevent.value} );
      }
    }

    requestPermission() {
      
      const messaging = firebase.default.messaging();
      
      messaging.getToken().then(token  => {

          const topic = `Desktop_all`

          this.subscribeTokenToTopic( token, topic);
          this.subscribeTokenToTopic( token, 'all');
          this.receiveMessage();
        
      }).catch(err => console.error(err));
    }


    subscribeTokenToTopic(token: string, topic: string) {
      fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `key=AAAAYElNUqs:APA91bHks6X7PX0VssK2jEvXDaCGYkRfIJGwbeazFPICoanV8gbtVBv8IlA7Nx9eKyeSyDLWqIBrRxXckLoJE2YDkcNYWGhzWfMoR3_JKe8URvuWApopO4AC0ua9e_kBenh7znXCRQAR`
        })
      })
        .then((response) => {
          if (response.status < 200 || response.status >= 400) {
            // console.log(response.status, response);
          }
          
        })
        .catch((error) => {
          // console.error(error.result);
        });
      return true;
    }

    receiveMessage() {
      const messaging = firebase.default.messaging();
      messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
        // ...
      });
    }
}
