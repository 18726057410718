import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TranslateLoader } from '@ngx-translate/core';
import { map, retryWhen, flatMap } from 'rxjs/operators';
import { interval, throwError, of } from 'rxjs';

@Injectable()
export class LiteralesService implements TranslateLoader {

    constructor(
        private http: HttpClient
    ) { }

    getTranslation(lang: string): Observable<any> {
        if ( localStorage.getItem( 'language') ) {
            lang = localStorage.getItem( 'language');
        }

        lang = lang.substring(0, 2);

        // let urlLiterales = `/consumidores/rest/i18n/get/${lang}`;
        let urlLiterales = '/assets/translations/es-ES.json';

        return this.http.get(urlLiterales).pipe(
            map((response: JSON) => {
                const json: JSON = response['HOLOCRON'];			
                // json['PASSREC']['RECOVERYEMAILINFO'] = json['PASSREC']['RECOVERYEMAILINFO'].replace("[routerLink]='['/guest/contact-information']'", '(click)="contactUs()"');
                return response['HOLOCRON'];
            }),
            retryWhen(_ => {
                let value: HttpErrorResponse = null;
                _.subscribe(err => {
                    value = err;
                });
                return interval(750).pipe(
                    flatMap(count => count === 5 ? throwError(value) : of(count))
                );
            })
        );
    }
}

